import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'

function ClientLogoCard({ item }) {
    // console.log("item ", item);

    return (
        <Card
            sx={{
                mx: 1, my: 1, border: '1px solid #31257B', borderRadius: '20px', position: 'relative', overflow: 'hidden',
                '&:hover .overlay': { // Targeting the content with class .overlay on hover
                    opacity: 1,
                    transform: 'translateY(0)',
                },
            }}
        >
            <CardMedia component="img" sx={{ width: '100%', objectFit: 'contain', height: 150, }} image={item?.logo} alt={item?.name} />
            <CardContent
                className="overlay" // Custom class to identify this content
                sx={{
                    opacity: 0, // Initially hidden
                    height: 10,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                    color: 'white', // Text color
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: 'opacity 0.4s ease, transform 0.4s ease', // Smooth transition for opacity and transform
                    transform: 'translateY(100%)', // Initially move it outside the view
                }}
            >
                <Typography variant="caption">{item?.name}</Typography>
            </CardContent>
        </Card>

    )
}

export default ClientLogoCard