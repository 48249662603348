import { Box, Button, Card, Grid, Typography } from '@mui/material'
import React from 'react'

function ServiceCard({ item }) {
    return (
        <Box
            sx={{
                mx: 1, my: 1,
                height: '50vh',
                flexGrow: 1,
                borderRadius: '10px 100px 10px 100px',
                bgcolor: '#019BE2',
                // width: '100%',
                position: 'relative',
                transition: 'background-color 0.3s ease', // Smooth transition for background color
                '&:hover': {
                    bgcolor: '#31257B', // Background color on hover
                },
            }}
        >
            {/* Parent Background Image */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/servicecard/${item?.bgImg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: '10px 100px 10px 100px',
                    zIndex: 1,
                }}
            />

            {/* Card with translucent background */}
            <Card
                sx={{
                    height: '100%',
                    borderRadius: '10px 150px 10px 150px',
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/servicecard/cardQuality.png)`,
                    position: 'relative',
                    zIndex: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transition: 'background-color 0.3s ease', // Smooth transition for background color
                    /* '&:hover': {
                        bgcolor: '#31257B', // Card background color on hover
                    }, */
                }}
            >
                {/* Card content */}
                <Box sx={{ mx: 3 }}>
                    <img
                        src="./images/servicecard/mobile.png"
                        alt="mobile"
                        style={{ width: '100px', height: '100px', alignSelf: 'start' }}
                    />
                    <Typography
                        variant="h6"
                        color="#31257B"
                        sx={{
                            fontSize: '30px',
                            fontWeight: 'bold',
                            transition: 'color 0.3s ease', // Smooth transition for text color
                            '&:hover': {
                                color: '#019BE2', // Text color on hover
                            },
                        }}
                    >
                        {item?.header}
                    </Typography>
                    <Typography
                        variant="h6"
                        color="#019BE2"
                        sx={{
                            fontSize: '30px',
                            fontWeight: 'bold',
                            transition: 'color 0.3s ease', // Smooth transition for text color
                            '&:hover': {
                                color: '#31257B', // Text color on hover
                            },
                        }}
                    >
                        {item?.title}
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            ml: 2,
                            bgcolor: '#019BE2',
                            alignSelf: 'start',
                            transition: 'background-color 0.3s ease', // Smooth transition for button color
                            '&:hover': {
                                bgcolor: '#31257B', // Button color on hover
                            },
                        }}
                        fullWidth
                    >
                        Click Here
                    </Button>
                </Box>
            </Card>
        </Box>
    )
}

export default ServiceCard

/* 

        <Box sx={{
            height: '50vh',
            flexGrow: 1,
            borderRadius: '10px 100px 10px 100px',
            bgcolor: '#019BE2',
            width: '100%',
            position: 'relative'
        }}>
            
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/servicecard/bgcard1.png)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: '10px 100px 10px 100px',
                    zIndex: 1
                }}
            />

            
            <Card
                sx={{
                    height: '100%',
                    borderRadius: '10px 150px 10px 150px',
                    // bgcolor: 'rgba(128, 128, 128, 0.5)', // Green with 50% transparency
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/servicecard/cardQuality.png)`,
                    position: 'relative',
                    zIndex: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                
                <Box sx={{ mx: 3 }}>
                    <img src="./images/servicecard/mobile.png" alt="mobile" style={{ width: '100px', height: '100px', alignSelf: 'start' }} />
                    <Typography variant="h6" color="#31257B" sx={{ fontSize: '30px', fontWeight: 'bold' }}>Mobile</Typography>
                    <Typography variant="h6" color="#019BE2" sx={{ fontSize: '30px', fontWeight: 'bold' }}>Development</Typography>
                    <Button variant="contained" sx={{ ml: 2, bgcolor: '#019BE2', alignSelf: 'start' }} fullWidth>Click Here</Button>
                </Box>

            </Card>
        </Box>
*/

