import React, { useRef, useState } from 'react';
import { Box } from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ServiceCard from './ServiceCard';
import CustomNextArrow from './CustomNextArrow';
import CustomPrevArrow from './CustomPrevArrow';

function ServiceSection() {
    const slideref = useRef(null)

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1, // Default to 1 slide on mobile
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        edgeFriction: 0.15,
        responsive: [
            {
                breakpoint: 600, // xs and sm
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 960, // md
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1280, // lg
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1440, // xl
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 2000, // Large screens
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 6000, // Large screens
                settings: {
                    slidesToShow: 5,
                },
            },
        ],
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };

    const [services, setServices] = useState([
        {
            header: 'Mobile',
            title: 'Development',
            link: '',
            logo: './images/servicecard/mobile.png',
            bgImg: 'bgcard1.png'
        },
        {
            header: 'Web',
            title: 'Development',
            link: '',
            logo: './images/servicecard/web.png',
            bgImg: 'bgcard2.png'
        },
        {
            header: 'UI/UX',
            title: 'Development',
            link: '',
            logo: './images/servicecard/uiux.png',
            bgImg: 'bgcard3.png'
        },
        {
            header: 'Digital',
            title: 'Maketting',
            link: '',
            logo: './images/servicecard/uiux.png',
            bgImg: 'bgcard3.png'
        },
        {
            header: 'WordPress',
            title: 'Maketting',
            link: '',
            logo: './images/servicecard/uiux.png',
            bgImg: 'bgcard3.png'
        },
    ]);

    return (
        <Slider {...settings}>
            {services.map((item, ind) => (
                <ServiceCard item={item} />
            ))}
        </Slider>
    );
}

export default ServiceSection;

{/* <Slider ref={slideref} {...settings}>
    {services.map((item, ind) => (
        <ServiceCard name={item.name} logo={item.logo} key={ind} />
    ))}
</Slider> */}