/* import React, { useState } from 'react';
import { Box, Card, Typography, Avatar, Grid } from '@mui/material';

const testimonials = [
    {
        name: 'Shifnpay',
        image: './images/assets/clientLogo/logoTwo.png',
        testimonial: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    {
        name: 'Gapshap',
        image: './images/assets/clientLogo/gapshap.png',
        testimonial: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    {
        name: 'Zcabs',
        image: './images/assets/clientLogo/zcabs.png',
        testimonial: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
];

const TestimonialCard = ({ testimonial, position, onClick }) => {
    const getTransform = () => {
        switch (position) {
            case 'center':
                return 'translateX(0) scale(1.1)';
            case 'left':
                return 'translateX(-150%) scale(0.9)';
            case 'right':
                return 'translateX(150%) scale(0.9)';
            case 'hidden-left':
                return 'translateX(-300%) scale(0.7)';
            case 'hidden-right':
                return 'translateX(300%) scale(0.7)';
            default:
                return 'translateX(0)';
        }
    };

    return (
        <Card
            onClick={onClick}
            sx={{
                width: 300,
                height: 400,
                borderRadius: '20px',
                padding: 2,
                textAlign: 'center',
                backgroundColor: position === 'center' ? '#31257B' : '#f8f9fc',
                color: position === 'center' ? '#fff' : '#000',
                boxShadow: position === 'center' ? '0px 10px 30px rgba(0, 0, 0, 0.2)' : '0px 5px 15px rgba(0, 0, 0, 0.1)',
                transform: getTransform(),
                transition: 'transform 0.5s ease-in-out',
                cursor: 'pointer',
                position: 'absolute',
                top: '0',
                left: '40%',
                transformOrigin: 'center',
            }}
        >
            <Avatar src={testimonial.image} alt={testimonial.name} sx={{ width: 100, height: 100, margin: '0 auto 10px', border: position === 'center' ? '4px solid white' : 'none', }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Testimonials</Typography>
            <Typography variant="body2" sx={{ marginTop: 1 }}>{testimonial.testimonial}</Typography>
            <Typography variant="h6" sx={{ marginTop: 2 }}>{testimonial.name}</Typography>
        </Card>
    );
};

const TestimonialCarousel = () => {
    const [centerIndex, setCenterIndex] = useState(1); // Start with the second card as center

    const getPosition = (index) => {
        const total = testimonials.length;
        if (index === centerIndex) return 'center';
        if (index === (centerIndex - 1 + total) % total) return 'left';
        if (index === (centerIndex + 1) % total) return 'right';
        if (index < centerIndex) return 'hidden-left';
        return 'hidden-right';
    };

    const handleCardClick = (index) => {
        setCenterIndex(index); // Move clicked card to center
    };

    return (
        <Box sx={{ position: 'relative', height: 400, marginTop: 4, }}>
            {testimonials.map((testimonial, index) => (
                <TestimonialCard key={index} testimonial={testimonial} position={getPosition(index)} onClick={() => handleCardClick(index)} />
            ))}
        </Box>
    );
};

const TestimonialsSection = () => {
    return (
        <Box sx={{}}>
            <Typography variant="h4" align="center" sx={{ my: 4 }}>Testimonials</Typography>
            <TestimonialCarousel />
        </Box>
    );
};

export default TestimonialsSection;
 */

/* import React, { useState } from 'react';
import { Box, Card, Typography, Avatar } from '@mui/material';

const testimonials = [
    {
        name: 'Shifnpay',
        image: './images/assets/clientLogo/logoTwo.png',
        testimonial: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    {
        name: 'Gapshap',
        image: './images/assets/clientLogo/gapshap.png',
        testimonial: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    {
        name: 'Zcabs',
        image: './images/assets/clientLogo/zcabs.png',
        testimonial: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
];

const TestimonialCard = ({ testimonial, position, onClick }) => {
    const getTransform = () => {
        switch (position) {
            case 'center':
                return 'translateX(0) scale(1.1)';
            case 'left':
                return 'translateX(-100%) scale(0.9)';
            case 'right':
                return 'translateX(100%) scale(0.9)';
            case 'hidden-left':
                return 'translateX(-200%) scale(0.8)';
            case 'hidden-right':
                return 'translateX(200%) scale(0.8)';
            default:
                return 'translateX(0)';
        }
    };

    return (
        <Card
            onClick={onClick}
            sx={{
                width: 300,
                height: 400,
                borderRadius: '20px',
                padding: 2,
                textAlign: 'center',
                backgroundColor: position === 'center' ? '#31257B' : '#f8f9fc',
                color: position === 'center' ? '#fff' : '#000',
                boxShadow: position === 'center' ? '0px 10px 30px rgba(0, 0, 0, 0.2)' : '0px 5px 15px rgba(0, 0, 0, 0.1)',
                transform: getTransform(),
                transition: 'transform 0.5s ease-in-out',
                cursor: 'pointer',
                position: 'absolute',
                top: '0',
                left: '50%',
                marginLeft: position === 'center' ? '-150px' : '-140px',
                transformOrigin: 'center',
            }}
        >
            <Avatar src={testimonial.image} alt={testimonial.name} sx={{ width: 100, height: 100, margin: '0 auto 10px', border: position === 'center' ? '4px solid white' : 'none', }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Testimonials</Typography>
            <Typography variant="body2" sx={{ marginTop: 1 }}>{testimonial.testimonial}</Typography>
            <Typography variant="h6" sx={{ marginTop: 2 }}>{testimonial.name}</Typography>
        </Card>
    );
};

const TestimonialCarousel = () => {
    const [centerIndex, setCenterIndex] = useState(1); // Start with the second card as center

    const getPosition = (index) => {
        const total = testimonials.length;
        if (index === centerIndex) return 'center';
        if (index === (centerIndex - 1 + total) % total) return 'left';
        if (index === (centerIndex + 1) % total) return 'right';
        if (index < centerIndex) return 'hidden-left';
        return 'hidden-right';
    };

    const handleCardClick = (index) => {
        setCenterIndex(index); // Move clicked card to center
    };

    return (
        <Box sx={{ position: 'relative', height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {testimonials.map((testimonial, index) => (
                <TestimonialCard key={index} testimonial={testimonial} position={getPosition(index)} onClick={() => handleCardClick(index)} />
            ))}
        </Box>
    );
};

const TestimonialsSection = () => {
    return (
        <Box sx={{}}>
            <Typography variant="h4" align="center" sx={{ my: 4 }}>Testimonials</Typography>
            <TestimonialCarousel />
        </Box>
    );
};

export default TestimonialsSection;
 */

import React, { useState } from 'react';
import { Box, Card, Typography, Avatar, Grid, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

const testimonials = [
    {
        name: 'Shifnpay',
        clientName: 'Shiftnpay',
        image: './images/assets/clientLogo/logoTwo.png',
        about: ['Employees Time Tracker', 'Employees Excel Sheet Export', 'Weekly Report', 'Monthly Report', 'Yearly Report', 'Rating System'],
        testimonial: '',
    },
    {
        name: 'Zcabs',
        clientName: 'Technosys',
        image: './images/assets/clientLogo/zcabs.png',
        about: ['Vehicle Live Tracking', 'E-Booking Cabs Service',],
        testimonial: '',
    },
    {
        name: 'Gapshap',
        clientName: 'Gapshap',
        image: './images/assets/clientLogo/gapshap.png',
        about: ['Simple User Interface', 'Responsive UI and Attracktive', 'Chating Social Services', 'Live Chat System in Android and iOS'],
        testimonial: '',
    },
];

const TestimonialCard = ({ testimonial, isCenter }) => {
    return (
        <Card
            sx={{
                width: '100%', maxHeight: 500, minHeight: 400, borderRadius: '20px', padding: 2, textAlign: 'center', backgroundColor: isCenter ? '#31257B' : '#f8f9fc',
                color: isCenter ? '#fff' : '#000', boxShadow: isCenter ? '0px 10px 30px rgba(0, 0, 0, 0.2)' : '0px 5px 15px rgba(0, 0, 0, 0.1)',
                position: 'relative', zIndex: isCenter ? 2 : 1, transform: isCenter ? 'scale(1.1)' : 'scale(1)', transition: 'transform 0.5s ease-in-out, z-index 0.5s',
                margin: '0 auto',
            }}
        >
            <Avatar src={testimonial.image} alt={testimonial.name} sx={{ width: 100, height: 100, margin: '0 auto 10px', border: isCenter ? '4px solid white' : 'none', }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{testimonial?.clientName}</Typography>
            <Typography variant="body2" sx={{ marginTop: 1 }}>{testimonial.testimonial}</Typography>
            <List sx={{ listStyleType: 'disc', paddingLeft: 2, color: isCenter ? '#fff' : '#000' }}>
                {testimonial.about.map((about, index) => (
                    <ListItem key={index} sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                        <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: isCenter ? '#fff' : '#000' }}>{about}</Typography>} />
                    </ListItem>
                ))}
            </List>
            <Typography variant="h6" sx={{ marginTop: 2 }}>{testimonial.name}</Typography>
        </Card >
    );
};

/* 
<ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                    <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>Email: sktechrise@gmail.com</Typography>} />
                </ListItem>
                <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                    <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>Mobile No.: +91 82105 74144</Typography>} />
                </ListItem>
                <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                    <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>Mobile No.: +91 62013 42801</Typography>} />
                </ListItem>
                <Link to={'https://www.linkedin.com/in/sk-techrise-74140b317/'} style={{ textDecoration: 'none', color: 'white' }} target='_blank'>
                    <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                        <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>LinkedIn: SK Techrise</Typography>} />
                    </ListItem>
                </Link>
*/

const TestimonialCarousel = () => {
    /* const [centerIndex, setCenterIndex] = useState(1); // Start with the second card as center

    const getPosition = (index) => {
        const total = testimonials.length;
        if (index === centerIndex) return 'center';
        if (index === (centerIndex - 1 + total) % total) return 'left';
        if (index === (centerIndex + 1) % total) return 'right';
        if (index < centerIndex) return 'hidden-left';
        return 'hidden-right';
    };

    const handleCardClick = (index) => {
        setCenterIndex(index); // Move clicked card to center
    }; */
    return (
        <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ padding: 4 }}        >
            {testimonials.map((testimonial, index) => (
                <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <TestimonialCard testimonial={testimonial} isCenter={index === 1} />
                </Grid>
            ))}
        </Grid>
    );
};

const TestimonialsSection = () => {
    return (
        <Box sx={{ padding: 4 }}>
            <Typography variant="h4" align="center" sx={{ marginBottom: 4 }}>Testimonials</Typography>
            <TestimonialCarousel />
        </Box>
    );
};

export default TestimonialsSection;
