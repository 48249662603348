import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ClientLogoCard from "./ClientLogoCard";
import { useState } from "react";

function HorizontalScroll() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        edgeFriction: 0.15
    };

    const [clients, setClients] = useState([
        {
            name: 'Shifnpay',
            logo: './images/assets/clientLogo/logoTwo.png'
        },
        {
            name: 'Gapshap',
            logo: './images/assets/clientLogo/gapshap.png'
        },
        {
            name: 'Zcabs',
            logo: './images/assets/clientLogo/zcabs.png'
        },
        {
            name: 'Jamshedpur Cane Furniture',
            logo: './images/assets/clientLogo/canFurniture.png'
        },
        {
            name: 'Majd',
            logo: './images/assets/clientLogo/majd.png'
        },
    ])


    return (
        <Slider {...settings}>
            {clients.map((item, ind) => {
                return <ClientLogoCard item={item} key={ind} />

            })}
        </Slider>
    );
}
export default HorizontalScroll