import React from 'react';
import { IconButton } from '@mui/material';
import { LuMoveLeft } from "react-icons/lu";

function CustomPrevArrow({ onClick }) {
    return (
        <IconButton
            onClick={onClick}
            sx={{
                position: 'absolute', // Change to fixed to place it at the bottom center of the screen
                bottom: '-20px', // Adjust distance from the bottom as needed
                left: '48%', // Center horizontally
                transform: 'translateX(-50%) translateY(50%)', // Center the arrow and move it down
                zIndex: 2,
                backgroundColor: '#fff',
                borderRadius: '50%',
                // boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                    backgroundColor: '#f0f0f0',
                }
            }}
        >
            <LuMoveLeft />
        </IconButton>
    )
}

export default CustomPrevArrow