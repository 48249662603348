import { Box, Typography } from '@mui/material'
import React from 'react'
import HorizontalScroll from '../common/HorizontalScroll'

function ClientLogo() {
    return (
        <Box sx={{ px: 4, my: 3 }}>
            <Box sx={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', my: 2 }}>
                <Typography variant='h4' component={'div'} sx={{ fontSize: 30, fontWeight: 700, mt: 2, textDecoration: 'underline', textTransform: 'capitalize' }}>Our Clients</Typography>
            </Box>
            <HorizontalScroll />
        </Box>
    )
}

export default ClientLogo