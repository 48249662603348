import { Box, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import whatsapp from '../utils/whatsapp';

function Footer() {

    const { redirectToWhatsApp } = whatsapp()

    const handleEmailClick = () => {
        // Replace with your email address
        const email = "sktechrise@gmail.com";
        const subject = "Regarding Software Solution And Maintaince!";
        const body = "I would like to inquire about your services.";

        // Construct the mailto URL
        // const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
            email
        )}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        // Redirect to the mailto URL
        window.location.href = gmailUrl;
    };

    return (
        <Box sx={{ bgcolor: '#31257B' }}>
            <Grid container spacing={2}>
                <Grid item md={4} lg={4} sm={6} xs={12}>
                    <Box sx={{ width: '100%', height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                        <img src="./images/assets/img/bottom.png" alt="logo" style={{ width: '100%', height: '150px', objectFit: 'contain' }} />
                    </Box>

                    <Box sx={{ padding: 2 }}>
                        <Typography sx={{ color: 'white', textAlign: 'justify' }}>"SK Techrise is a trusted partner in turning your vision into reality. We specialize in developing tailored digital solutions, from mobile apps to full-scale web platforms. Let us help you grow with cutting-edge technology and innovative digital strategies. Contact us today to take your business to the next level."</Typography>
                    </Box>
                </Grid>
                <Grid item md={4} lg={4} sm={6} xs={12}>
                    <Box sx={{ width: '100%', alignItems: 'center', justifyContent: 'center', }}>
                        <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: 25, textTransform: 'capitalize' }}>Navigation</Typography>
                        {/* <Typography sx={{ color: 'white' }}>"SK Techrise is a trusted partner in turning your vision into reality. We specialize in developing tailored digital solutions, from mobile apps to full-scale web platforms. Let us help you grow with cutting-edge technology and innovative digital strategies. Contact us today to take your business to the next level."</Typography> */}


                        <List sx={{ listStyleType: 'disc', paddingLeft: 2, color: 'white', my: 1 }}>
                            <Link to={'#'} style={{ textDecoration: 'none', color: 'white' }} >
                                <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                    {/* <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 300, color: 'white' }}>Services: Android | iOS | Web Development | eCommerce | WooCommerce | Digital Marketing</Typography>} /> */}
                                    <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>Home</Typography>} />
                                </ListItem>
                            </Link>

                            <Link to={'#'} style={{ textDecoration: 'none', color: 'white' }} >
                                <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                    <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>Service</Typography>} />
                                    {/* <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 300, color: 'white' }}>© 2024 SK Techrise. All Rights Reserved.</Typography>} /> */}
                                </ListItem>
                            </Link>

                            <Link to={'#'} style={{ textDecoration: 'none', color: 'white' }} >
                                <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                    <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>Gallery</Typography>} />
                                    {/* <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 300, color: 'white' }}>© 2024 SK Techrise. All Rights Reserved.</Typography>} /> */}
                                </ListItem>
                            </Link>

                            <Link to={'#'} style={{ textDecoration: 'none', color: 'white' }} >
                                <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                    <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>Contact Us | Privacy Policy</Typography>} />
                                </ListItem>
                            </Link>
                            <Link to={'#'} style={{ textDecoration: 'none', color: 'white' }} >
                                <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                    <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>About Us</Typography>} />
                                </ListItem>
                            </Link>
                        </List>
                    </Box>
                </Grid>

                <Grid item md={4} lg={4} sm={6} xs={12}>
                    <Box sx={{ width: '100%', display: 'flex', /* alignItems: 'center', */ justifyContent: 'center', flexDirection: 'column' }}>
                        <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: 25, textTransform: 'capitalize' }}>Contact Us</Typography>

                        <Box sx={{ mt: 2, ml: 3 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <FaPhoneAlt color='white' size={20} style={{ marginRight: '12px' }} />
                                <Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>+91 82105 74144, +91 62013 42801</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <IoLogoWhatsapp color='white' size={22} style={{ marginRight: '12px' }} />
                                <Typography variant="body1" sx={{ fontWeight: 500, color: 'white', ':hover': { cursor: 'pointer' } }}><span onClick={() => redirectToWhatsApp('+918210574144')}>+91 82105 74144</span>,<span onClick={() => redirectToWhatsApp('+916201342801')}> +91 62013 42801</span></Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', alignItems: 'center', mb: 1 }}>
                                <MdEmail color='white' size={22} style={{ marginRight: '12px' }} />
                                <Typography variant="body1" sx={{ fontWeight: 500, color: 'white', ':hover': { cursor: 'pointer' } }} onClick={handleEmailClick}>sktechrise@gmail.com</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', alignItems: 'center', mb: 1 }}>
                                <FaLinkedin color='white' size={22} style={{ marginRight: '12px' }} />
                                <Link to={'https://www.linkedin.com/in/sk-techrise-74140b317/'} style={{ textDecoration: 'none', color: 'white' }} target='_blank'>
                                    <Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>SK Techrise</Typography>
                                </Link>
                            </Box>
                        </Box>

                        {/* <List sx={{ listStyleType: 'disc', paddingLeft: 2, color: 'white' }}>
                            <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>Email: sktechrise@gmail.com</Typography>} />
                            </ListItem>
                            <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>Mobile No.: +91 82105 74144, +91 62013 42801</Typography>} />
                            </ListItem>
                            {/* <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>Mobile No.: +91 62013 42801</Typography>} />
                            </ListItem> 
                            <Link to={'https://www.linkedin.com/in/sk-techrise-74140b317/'} style={{ textDecoration: 'none', color: 'white' }} target='_blank'>
                                <ListItem sx={{ display: 'list-item', listStyleType: 'circle', marginLeft: 2, my: -2 }}>
                                    <ListItemText primary={<Typography variant="body1" sx={{ fontWeight: 500, color: 'white' }}>LinkedIn: SK Techrise</Typography>} />
                                </ListItem>
                            </Link>
                        </List> */}
                    </Box>
                </Grid>

            </Grid>

            <Box style={{ width: '100%', height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                <Typography variant="body1" sx={{ fontWeight: 300, color: 'white', alignSelf: 'center' }}>© 2024 SK Techrise. All Rights Reserved.</Typography>
            </Box>
        </Box>
    )
}

export default Footer