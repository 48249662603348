import React from 'react'

function whatsapp() {

    const redirectToWhatsApp = (phoneNumber) => {
        // const phoneNumber = "+918092144414"; // Your WhatsApp number here
        // const id = product.id;
        // const productName = product.name;
        // const productPrice = product.price;
        // const productImage = product.image; // Image URL

        const message = `Hello, I am interested in your service`

        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

        // Open the WhatsApp chat link in a new tab
        window.open(whatsappUrl, '_blank');
    };


    return { redirectToWhatsApp }

}

export default whatsapp